import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import Layout from "../components/layout"
import SEO from "../components/seo"
import servicesStyles from "./services.module.css"



const Services = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "services.title" })} />
    <h1>
      <FormattedMessage id="services.title" />
    </h1>

    <div className={servicesStyles.wrapper}>
      <ReactPlayer url={intl.formatMessage({ id: "services.video_url" })} playing={false}
        width='100%'
        height='100%'
        className={classNames(servicesStyles.player, servicesStyles.box1)}
      />
      <div className={classNames(servicesStyles.box1, servicesStyles.box2)}></div>
    </div>

    <h3>
      <FormattedMessage id="services.executive_coaching_title" />
    </h3>
    <p>
      <FormattedMessage id="services.executive_coaching_text" />
    </p>

    <h3>
      <FormattedMessage id="services.career_coaching_title" />
    </h3>
    <p>
      <FormattedMessage id="services.career_coaching_text" />
    </p>

    <h3>
      <FormattedMessage id="services.mentor_coaching_title" />
    </h3>
    <p>
      <FormattedMessage id="services.mentor_coaching_text" />
    </p>

    <h3>
      <FormattedMessage id="services.workshop_title" />
    </h3>
    <p>
      <FormattedMessage id="services.workshop_text" />
    </p>

  </Layout>
)

export default injectIntl(Services)
